import payload_plugin_oi7RiaeDOK from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.25.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_hKK1rrF8Aw from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.0_ioredis@5.4.1_magicast@0_r54ct2r3dq3ddjrx67wfjsnusu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_aUjiXqSHyc from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.0_ioredis@5.4.1_magicast@0_r54ct2r3dq3ddjrx67wfjsnusu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ga8lCejKkr from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.0_ioredis@5.4.1_magicast@0_r54ct2r3dq3ddjrx67wfjsnusu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_6Fv4u9KSyH from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.0_ioredis@5.4.1_magicast@0_r54ct2r3dq3ddjrx67wfjsnusu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_6DyotNsNUU from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.0_ioredis@5.4.1_magicast@0_r54ct2r3dq3ddjrx67wfjsnusu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_NoC0RsZNpA from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.0_ioredis@5.4.1_magicast@0_r54ct2r3dq3ddjrx67wfjsnusu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ExuFn47bG1 from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.0_ioredis@5.4.1_magicast@0_r54ct2r3dq3ddjrx67wfjsnusu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_2ZWskWUYmK from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.25.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/Hangar/Hangar/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_x2jy18srbf from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.0_ioredis@5.4.1_magicast@0_r54ct2r3dq3ddjrx67wfjsnusu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_K6IaZztiiN from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.25.0_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_aS95I9tdkQ from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.25.0_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import unocss_MzCDxu9LMj from "/home/runner/work/Hangar/Hangar/frontend/.nuxt/unocss.mjs";
import axios_OijlQmfXsA from "/home/runner/work/Hangar/Hangar/frontend/src/plugins/axios.ts";
import dompurify_client_EYdHkZatd0 from "/home/runner/work/Hangar/Hangar/frontend/src/plugins/dompurify.client.ts";
import nprogress_lI8WDWlVpm from "/home/runner/work/Hangar/Hangar/frontend/src/plugins/nprogress.ts";
import sentry_client_KAXFuL2wum from "/home/runner/work/Hangar/Hangar/frontend/src/plugins/sentry.client.ts";
export default [
  payload_plugin_oi7RiaeDOK,
  revive_payload_client_hKK1rrF8Aw,
  unhead_aUjiXqSHyc,
  router_ga8lCejKkr,
  payload_client_6Fv4u9KSyH,
  navigation_repaint_client_6DyotNsNUU,
  check_outdated_build_client_NoC0RsZNpA,
  chunk_reload_client_ExuFn47bG1,
  plugin_vue3_2ZWskWUYmK,
  components_plugin_KR1HBZs4kY,
  prefetch_client_x2jy18srbf,
  switch_locale_path_ssr_K6IaZztiiN,
  i18n_aS95I9tdkQ,
  unocss_MzCDxu9LMj,
  axios_OijlQmfXsA,
  dompurify_client_EYdHkZatd0,
  nprogress_lI8WDWlVpm,
  sentry_client_KAXFuL2wum
]