import { default as channelsh2kKIXzuD8Meta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/channels.vue?macro=true";
import { default as discussHBqZRKYHbOMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/discuss.vue?macro=true";
import { default as flagsheVjUO1vDMMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/flags.vue?macro=true";
import { default as index0Rr14NCsqKMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/index.vue?macro=true";
import { default as notesnPFLxAqKt9Meta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/notes.vue?macro=true";
import { default as _91_46_46_46page_93pLbIYnSzzQMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/pages/[...page].vue?macro=true";
import { default as _91_46_46_46slug_93O8Ys5EtChPMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/settings/[...slug].vue?macro=true";
import { default as starsRjGtqEQVWOMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/stars.vue?macro=true";
import { default as indexnoOg95y4YQMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/versions/[version]/index.vue?macro=true";
import { default as reviewsS1WCjMR9piMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/versions/[version]/reviews.vue?macro=true";
import { default as scandG5Tac2SZGMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/versions/[version]/scan.vue?macro=true";
import { default as _91version_93PZcous2atmMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/versions/[version].vue?macro=true";
import { default as index0oDXwej6qwMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/versions/index.vue?macro=true";
import { default as newpca0jyPcZEMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/versions/new.vue?macro=true";
import { default as watchersp3bkf8spSqMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/watchers.vue?macro=true";
import { default as _91project_93h8gtMqD9zhMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project].vue?macro=true";
import { default as indexuF4QEu6e9mMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/index.vue?macro=true";
import { default as _91user_93Js0Xm7cz7hMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/[user].vue?macro=true";
import { default as _91user_93B5XwVM7oGtMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/admin/activities/[user].vue?macro=true";
import { default as projects1OqpN1FYp5Meta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/admin/approval/projects.vue?macro=true";
import { default as versions9J59LPb6MtMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/admin/approval/versions.vue?macro=true";
import { default as flagsMxTvZaSwgpMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/admin/flags.vue?macro=true";
import { default as healthOYQsxCWILQMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/admin/health.vue?macro=true";
import { default as logJmJOGmJFeiMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/admin/log.vue?macro=true";
import { default as settingsd8rXnvkXJdMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/admin/settings.vue?macro=true";
import { default as statsfNXRdEuw5lMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/admin/stats.vue?macro=true";
import { default as _91user_93ZJNhwxmna6Meta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/admin/user/[user].vue?macro=true";
import { default as indexDPlMce4UVyMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/admin/user/index.vue?macro=true";
import { default as adminYm4fOs87l0Meta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/admin.vue?macro=true";
import { default as api_45docsb3GMtPEBVgMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/api-docs.vue?macro=true";
import { default as loginqLVwOVIc9QMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/auth/login.vue?macro=true";
import { default as oauth_45signupOrak4pJEq5Meta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/auth/oauth-signup.vue?macro=true";
import { default as resetXnJg3dQHZWMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/auth/reset.vue?macro=true";
import { default as account5hFs13dbtbMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/auth/settings/account.vue?macro=true";
import { default as api_45keyscW9hL3zhg6Meta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/auth/settings/api-keys.vue?macro=true";
import { default as othermDrmOD3OmcMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/auth/settings/other.vue?macro=true";
import { default as profilejzD65fCAx3Meta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/auth/settings/profile.vue?macro=true";
import { default as security7bJYJQPCgZMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/auth/settings/security.vue?macro=true";
import { default as settingsYgsIhRMcydMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/auth/settings.vue?macro=true";
import { default as signuprsJBmt1ixZMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/auth/signup.vue?macro=true";
import { default as authorscBy5n55XvEMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/authors.vue?macro=true";
import { default as errory5s0rhUDrvMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/error.vue?macro=true";
import { default as guidelinesKxxRHViQJ4Meta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/guidelines.vue?macro=true";
import { default as indexrGCKgOKPiaMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/index.vue?macro=true";
import { default as linkout1dT5HD3pa3Meta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/linkout.vue?macro=true";
import { default as new8bSQv66egMMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/new.vue?macro=true";
import { default as neworganizationZU4k1vfPr9Meta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/neworganization.vue?macro=true";
import { default as notificationsdXxgLEATu0Meta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/notifications.vue?macro=true";
import { default as paperAupVle6icAMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/paper.vue?macro=true";
import { default as privacyMLQZ4ugjs2Meta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/privacy.vue?macro=true";
import { default as staffSzIKq3T1kqMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/staff.vue?macro=true";
import { default as termsR7JL7JcL7PMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/terms.vue?macro=true";
import { default as bbcoded5C1PgW6IKMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/tools/bbcode.vue?macro=true";
import { default as importerBbY48hwkdcMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/tools/importer.vue?macro=true";
import { default as markdownujNNyKFThzMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/tools/markdown.vue?macro=true";
import { default as velocitymgnBRvrATOMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/velocity.vue?macro=true";
import { default as version1jP2v5V14WMeta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/version.vue?macro=true";
import { default as waterfallwia1bvGfS7Meta } from "/home/runner/work/Hangar/Hangar/frontend/src/pages/waterfall.vue?macro=true";
export default [
  {
    name: _91user_93Js0Xm7cz7hMeta?.name,
    path: "/:user()",
    meta: _91user_93Js0Xm7cz7hMeta || {},
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/[user].vue"),
    children: [
  {
    name: _91project_93h8gtMqD9zhMeta?.name,
    path: ":project()",
    meta: _91project_93h8gtMqD9zhMeta || {},
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project].vue"),
    children: [
  {
    name: "user-project-channels",
    path: "channels",
    meta: channelsh2kKIXzuD8Meta || {},
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/channels.vue")
  },
  {
    name: "user-project-discuss",
    path: "discuss",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/discuss.vue")
  },
  {
    name: "user-project-flags",
    path: "flags",
    meta: flagsheVjUO1vDMMeta || {},
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/flags.vue")
  },
  {
    name: "user-project",
    path: "",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/index.vue")
  },
  {
    name: "user-project-notes",
    path: "notes",
    meta: notesnPFLxAqKt9Meta || {},
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/notes.vue")
  },
  {
    name: "user-project-pages-page",
    path: "pages/:page(.*)*",
    meta: _91_46_46_46page_93pLbIYnSzzQMeta || {},
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/pages/[...page].vue")
  },
  {
    name: "user-project-settings-slug",
    path: "settings/:slug(.*)*",
    meta: _91_46_46_46slug_93O8Ys5EtChPMeta || {},
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/settings/[...slug].vue")
  },
  {
    name: "user-project-stars",
    path: "stars",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/stars.vue")
  },
  {
    name: _91version_93PZcous2atmMeta?.name,
    path: "versions/:version()",
    meta: _91version_93PZcous2atmMeta || {},
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/versions/[version].vue"),
    children: [
  {
    name: "user-project-versions-version",
    path: "",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/versions/[version]/index.vue")
  },
  {
    name: "user-project-versions-version-reviews",
    path: "reviews",
    meta: reviewsS1WCjMR9piMeta || {},
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/versions/[version]/reviews.vue")
  },
  {
    name: "user-project-versions-version-scan",
    path: "scan",
    meta: scandG5Tac2SZGMeta || {},
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/versions/[version]/scan.vue")
  }
]
  },
  {
    name: "user-project-versions",
    path: "versions",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/versions/index.vue")
  },
  {
    name: "user-project-versions-new",
    path: "versions/new",
    meta: newpca0jyPcZEMeta || {},
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/versions/new.vue")
  },
  {
    name: "user-project-watchers",
    path: "watchers",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/[project]/watchers.vue")
  }
]
  },
  {
    name: "user",
    path: "",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/[user]/index.vue")
  }
]
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/admin.vue"),
    children: [
  {
    name: "admin-activities-user",
    path: "activities/:user()",
    meta: _91user_93B5XwVM7oGtMeta || {},
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/admin/activities/[user].vue")
  },
  {
    name: "admin-approval-projects",
    path: "approval/projects",
    meta: projects1OqpN1FYp5Meta || {},
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/admin/approval/projects.vue")
  },
  {
    name: "admin-approval-versions",
    path: "approval/versions",
    meta: versions9J59LPb6MtMeta || {},
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/admin/approval/versions.vue")
  },
  {
    name: "admin-flags",
    path: "flags",
    meta: flagsMxTvZaSwgpMeta || {},
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/admin/flags.vue")
  },
  {
    name: "admin-health",
    path: "health",
    meta: healthOYQsxCWILQMeta || {},
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/admin/health.vue")
  },
  {
    name: "admin-log",
    path: "log",
    meta: logJmJOGmJFeiMeta || {},
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/admin/log.vue")
  },
  {
    name: "admin-settings",
    path: "settings",
    meta: settingsd8rXnvkXJdMeta || {},
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/admin/settings.vue")
  },
  {
    name: "admin-stats",
    path: "stats",
    meta: statsfNXRdEuw5lMeta || {},
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/admin/stats.vue")
  },
  {
    name: "admin-user-user",
    path: "user/:user()",
    meta: _91user_93ZJNhwxmna6Meta || {},
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/admin/user/[user].vue")
  },
  {
    name: "admin-user",
    path: "user",
    meta: indexDPlMce4UVyMeta || {},
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/admin/user/index.vue")
  }
]
  },
  {
    name: "api-docs",
    path: "/api-docs",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/api-docs.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/auth/login.vue")
  },
  {
    name: "auth-oauth-signup",
    path: "/auth/oauth-signup",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/auth/oauth-signup.vue")
  },
  {
    name: "auth-reset",
    path: "/auth/reset",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/auth/reset.vue")
  },
  {
    name: "auth-settings",
    path: "/auth/settings",
    meta: settingsYgsIhRMcydMeta || {},
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/auth/settings.vue"),
    children: [
  {
    name: "auth-settings-account",
    path: "account",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/auth/settings/account.vue")
  },
  {
    name: "auth-settings-api-keys",
    path: "api-keys",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/auth/settings/api-keys.vue")
  },
  {
    name: "auth-settings-other",
    path: "other",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/auth/settings/other.vue")
  },
  {
    name: "auth-settings-profile",
    path: "profile",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/auth/settings/profile.vue")
  },
  {
    name: "auth-settings-security",
    path: "security",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/auth/settings/security.vue")
  }
]
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/auth/signup.vue")
  },
  {
    name: "authors",
    path: "/authors",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/authors.vue")
  },
  {
    name: "error",
    path: "/error",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/error.vue")
  },
  {
    name: "guidelines",
    path: "/guidelines",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/guidelines.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/index.vue")
  },
  {
    name: "linkout",
    path: "/linkout",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/linkout.vue")
  },
  {
    name: "new",
    path: "/new",
    meta: new8bSQv66egMMeta || {},
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/new.vue")
  },
  {
    name: "neworganization",
    path: "/neworganization",
    meta: neworganizationZU4k1vfPr9Meta || {},
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/neworganization.vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: notificationsdXxgLEATu0Meta || {},
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/notifications.vue")
  },
  {
    name: "paper",
    path: "/paper",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/paper.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/privacy.vue")
  },
  {
    name: "staff",
    path: "/staff",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/staff.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/terms.vue")
  },
  {
    name: "tools-bbcode",
    path: "/tools/bbcode",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/tools/bbcode.vue")
  },
  {
    name: "tools-importer",
    path: "/tools/importer",
    meta: importerBbY48hwkdcMeta || {},
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/tools/importer.vue")
  },
  {
    name: "tools-markdown",
    path: "/tools/markdown",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/tools/markdown.vue")
  },
  {
    name: "velocity",
    path: "/velocity",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/velocity.vue")
  },
  {
    name: "version",
    path: "/version",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/version.vue")
  },
  {
    name: "waterfall",
    path: "/waterfall",
    component: () => import("/home/runner/work/Hangar/Hangar/frontend/src/pages/waterfall.vue")
  }
]