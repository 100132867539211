import routerOptions0 from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.0_ioredis@5.4.1_magicast@0_r54ct2r3dq3ddjrx67wfjsnusu/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/home/runner/work/Hangar/Hangar/frontend/src/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}